import { template as template_1a4d70bd4b2d4237b5137fdd2da7ae34 } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
import { i18n } from "discourse-i18n";
const CategoryNotificationsTracking = template_1a4d70bd4b2d4237b5137fdd2da7ae34(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{@showCaret}}
    @showFullTitle={{@showFullTitle}}
    @prefix="category.notifications"
    @title={{i18n "category.notifications.title"}}
    class="category-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryNotificationsTracking;
