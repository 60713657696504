import { template as template_c25b15c6be1e4210aa65f5b5dbccfbd5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c25b15c6be1e4210aa65f5b5dbccfbd5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
